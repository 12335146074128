
@mixin background-color($name, $value) { 
    $multiplier: map-get($_multipliers, $name);

    .bgc-#{$name} {
        background-color: $value !important;
    }
    .brc-#{$name} {
        border-color: $value !important;
    }

    @if not($name == 'black' or $name == 'white') {
        @for $i from 1 through $transparent-color-count {
            .bgc-#{$name}-tp#{$i} {//transparency
                background-color: rgba($value, 1 - ($i*0.08)) !important;
            }
            .brc-#{$name}-tp#{$i} {//transparency
                border-color: rgba($value, 1 - ($i*0.08)) !important;
            }
        }
    }

    @for $i from 1 through $dark-color-count {
        $_value: dark-bgc-color($name, $i);

        .bgc-#{$name}-d#{$i} {
            background-color: $_value !important;
        }
        @if $dark-borders == true {
            //no dark border colors?
            .brc-#{$name}-d#{$i} {
                border-color: $_value !important;
            }
        }
    }


    @for $i from 1 through $medium-color-count {
        $_value: medium-bgc-color($name, $i);

        @if lightness($_value) < 100 {//skip bgc-white-m3, etc ...
            .bgc-#{$name}-m#{$i} {
                background-color: $_value !important;
            }
            .brc-#{$name}-m#{$i}, .brc-h-#{$name}-m#{$i}:hover {
                border-color: $_value !important;
            }
        }
    }

    @for $i from 1 through $light-color-count {
        $_value: light-bgc-color($name, $i);

        @if lightness($_value) < 100 {//skip bgc-light-l4, etc ...
            .bgc-#{$name}-l#{$i}, .bgc-h-#{$name}-l#{$i}:hover {
                background-color: $_value !important;
            }
            .brc-#{$name}-l#{$i} {
                border-color: $_value !important;
            }
        }
    }
}


$btn-light-colors: ();
$btn-light-text-colors: ();
$btn-light-border-colors: ();

@each $name, $value in $background-colors {
    @include background-color($name, $value);

    $btn-light-colors: map-merge($btn-light-colors,
    (
       $name: light-bgc-color($name, 3)
    ));

    $btn-light-text-colors: map-merge($btn-light-text-colors,
    (
        $name: dark-bgc-color($name, 3)
    ));

    $btn-light-border-colors: map-merge($btn-light-border-colors,
    (
        $name: medium-bgc-color($name, 4)
    ));
}




.bgc-transparent {
    background-color: transparent !important;
}
[class*="bgc-h-"] {
    transition: background-color $transition-general;
}

.brc-transparent {
    border-color: transparent !important;
}
[class*="brc-h-"] {
    transition: border-color $transition-general;
}


.brc-black { border-color: #000 !important; }
.brc-white { border-color: #fff !important; }

.bgc-current { background-color: currentColor !important; }
.brc-current { border-color: currentColor !important; }

@for $i from 1 through 10 {
    .bgc-black-tp#{$i} , .bgc-h-black-tp#{$i}:hover {
        background-color: rgba(#000, 1 - ($i*0.0925)) !important;
    }
    .bgc-white-tp#{$i} , .bgc-h-white-tp#{$i}:hover {
        background-color: rgba(#fff, 1 - ($i*0.0925)) !important;
    }
}
@for $i from 1 through 10 {
    .brc-black-tp#{$i} {
        border-color: rgba(#000, 1 - ($i*0.0925)) !important;
    }
    .brc-white-tp#{$i} {
        border-color: rgba(#fff, 1 - ($i*0.0925)) !important;
    }
}

.bgc-dark-grad {
    background-image: linear-gradient(rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.33) 100%);
}
.bgc-light-grad {
    background-image: linear-gradient(rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.33) 100%);
}
.bgc-white-grad {
    background-image: linear-gradient(rgba(255, 255, 255, 0.87) 0%, rgba(255, 255, 255, 0.33) 50%, rgba(255, 255, 255, 0.87) 100%);
}

.bg-none {
    background-image: none !important;
}






.border-0 {//override bootstrap's
    border-width: 0px !important;
    border-style: solid !important;
    border-color: transparent;
}
.border-none {
    border-width: 0px !important;
    border-style: solid;
    border-color: transparent;
}


@for $i from 1 through 4 {
    .border-#{$i} {
        border-width: $i*1px !important;
        border-style: solid !important;
        border-color: transparent;
    }
}

@for $i from 0 through 4 {
    .border-t-#{$i} , .border-y-#{$i} {
        border-top-width: $i*1px !important;        
        border-top-style: solid !important;
        border-top-color: transparent;
    }
    .border-b-#{$i} , .border-y-#{$i} {
        border-bottom-width: $i*1px !important;
        border-bottom-style: solid !important;
        border-bottom-color: transparent;
    }
    .border-r-#{$i} , .border-x-#{$i} {
        border-right-width: $i*1px !important;
        border-right-style: solid !important;
        border-right-color: transparent;
    }
    .border-l-#{$i} , .border-x-#{$i} {
        border-left-width: $i*1px !important;
        border-left-style: solid !important;
        border-left-color: transparent;
    }
}

.border-solid {
    border-style: solid !important;
}
.border-dotted {
    border-style: dotted !important;
}
.border-double {
    border-style: double !important;
}
.border-dashed {
    border-style: dashed !important;
}
////////
//small border below element
[class*="b-underline-"] {
    position: relative;

    &::after {
        content: "";
        display: block;
    
        position: absolute;
        bottom: -0.25rem;
        left: 1px;
    
        border-bottom: 2px solid;
    
        border-color: currentColor;
        opacity: 0.55;
        max-width: 80%;
    }
}
[class*="b-underline-n"]::after {
    max-width: none;
}
.b-centered::after {
    left: 0;
    right: 0;
    margin: 0 auto;
}


@for $i from 0 through 5 {
    .b-underline-#{$i}::after {
        width: $i*1rem;
    }
    .b-underline-n#{$i}::after {
        width: calc(100% - #{$i*1rem});
    }
}

.b-hovered::after {
    @include transition(width $transition-general, max-width 0s $transition-general);
}
.b-hovered:hover::after {
    width: 100%;
    max-width: 100%;
    transition-delay: 0s, 0s;
}


/////////



@for $i from 0 through 5 {
    .radius-#{$i} {
      border-radius: ($i*0.25)*1rem !important;
    }
}
@for $i from 1 through 3 {
    .radius-#{$i}px {
      border-radius: ($i)*1px !important;
    }
}
@for $i from 0 through 5 {
    .radius-l-#{$i} {
        border-top-left-radius: ($i*0.25)*1rem !important;
        border-bottom-left-radius: ($i*0.25)*1rem !important;
    }
    .radius-t-#{$i} {
        border-top-left-radius: ($i*0.25)*1rem !important;
        border-top-right-radius: ($i*0.25)*1rem !important;
    }
    .radius-r-#{$i} {
        border-bottom-right-radius: ($i*0.25)*1rem !important;
        border-top-right-radius: ($i*0.25)*1rem !important;
    }
    .radius-b-#{$i} {
        border-bottom-left-radius: ($i*0.25)*1rem !important;
        border-bottom-right-radius: ($i*0.25)*1rem !important;
    }
}

.radius-round {
    border-radius: 4rem !important;
}
.radius-l-round {
    border-top-left-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
}
.radius-t-round {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
}
.radius-r-round {
    border-bottom-right-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
}
.radius-b-round {
    border-bottom-left-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
}

.radius-100 {
    border-radius: 100% !important;
}