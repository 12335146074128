// enable/disable sidebar different collapsed modes
// only one should be 'true'
$expandable: true;// when sidebar is collapsed and mouse hovers it, it is expanded to its full size
$hoverable: true;// when sidebar is collapsed and an item is hovered, its submenu will be shown like a popup menu
$hideable: true;// when sidebar is collapsed it gets hidden completely

$horizontal: true;// enable/disable horizontal sidebar
$sidebar-hover: true;// display submenu when mouse hovers it

$sidebar-focus-expand: false;// expands collapsed sidebar when an element inside it receives focus
$sidebar-focus-highlight: false;// highlight sidebar elements when they receive focus. the highlight is like hover

$sidebar-spaced: true;// sidebar elements with a little space from sides

$sidebar-active-arrow: true;// the white arrow pointing to active element in dark sidebars
$sidebar-active-border: true;// the border on left or right of active sidebar elements

$collapsed-sidebar-caption-no-margin: false;// if true when sidebar is collapsed, captions won't have margin-top

$sidebar-active-bold: false;// should active sidebar item be bold in all cases?
$sidebar-submenu-active-bold: false;// should active sidebar item be bold in all cases?


//
$body-bg-themes: true;// the extra body background colors

// buttons
$btn-light-style: true;// i.e. .btn-light-danger & .btn-lighter-danger, etc 
$btn-sliding-text: true;// buttons with sliding text/icon
$btn-app: true;// big buttons with gradient background
$btn-focus-states: true;// different focus states for buttons such .fs--shadow , etc

$badge-arrow: true;// arrowed badges


// dropdowns
$mobile-dropdowns: true;// dropdowns slide from bottom on small devices

// for elements
$custom-control: true;// custom checkbox and radios
$custom-switch: true;// custom switches
$custom-file-input: true;
$custom-select: true;

$custom-asides: true;

$animated-icons: true;


// tabs
$page-tabs: true;

$tabs-below: true;
$tabs-left: true;
$tabs-right: true;

$tabs-color: true;


// plugins
$plugin-basictable: true;// the responsive table plugin

$plugin-bootstraptable: true;
$plugin-bootstraptable-sorticonsize: 0.3125rem;

$plugin-datatables: true;
$plugin-datatables-sorticonsize: 0.3125rem;

$plugin-jqgrid: true;


$plugin-datepicker: true;
$plugin-timepicker: true;
$plugin-nouislider: true;
$plugin-ionslider: true;
$plugin-touchspin: true;

$plugin-dropzone: true;
$plugin-summernote: true;
$plugin-markdown: true;
$plugin-wysiwyg: true;
$plugin-rating: true;
$plugin-typeahead: true;
$plugin-tagsinput: true;

$plugin-chosen: true;
$plugin-select2: true;

$plugin-fullcalendar: true;

$plugin-treeview: true;
